<template>
  <el-dialog title="创建工单" width="1200px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail.data"
      />
      <div class="f-bold m-b-10">所需物料</div>
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn1"
        :data="selfDetail.need_list"
      >
      </vxe-grid>
      <div class="f-bold m-y-10">生产产品</div>
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :columns="tableColumn2"
        :data="selfDetail.list"
      >
      </vxe-grid>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupPush">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        data: {
          ji_hua_kai_gong_date: '', // 计划开工日期
          ji_hua_wan_cheng_date: '', // 计划完工日期
          cao_zuo_yuan: '' // 工单负责人
        },
        need_list: [],
        list: [],
        process: {}
      },
      formConfig: {
        inline: true,
        width: '240px'
      },
      formColumn: [
        {
          type: 'date',
          title: '计划开工日期',
          field: 'ji_hua_kai_gong_date',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'date',
          title: '计划完工日期',
          field: 'ji_hua_wan_cheng_date',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'select',
          title: '工单负责人',
          field: 'cao_zuo_yuan',
          options: []
        }
      ],
      tableColumn1: [
        {
          title: '物料号',
          field: 'wu_liao_code'
        },
        {
          title: '品名',
          field: 'craft'
        },
        {
          title: '工艺',
          field: 'process_name'
        },
        {
          title: '材质',
          field: 'texture'
        },
        {
          title: '规格',
          field: 'sales_specification'
        },
        {
          title: '单位',
          field: 'unit'
        },
        {
          title: '入库数量',
          field: 'ru_ku_stock_counts'
        },
        {
          title: '仓库',
          field: 'cang_ku'
        }
      ],
      tableColumn2: [
        {
          title: '物料号',
          field: 'wu_liao_code'
        },
        {
          title: '品名',
          field: 'craft'
        },
        {
          title: '工艺',
          field: 'process_name'
        },
        {
          title: '材质',
          field: 'texture'
        },
        {
          title: '规格',
          field: 'sales_specification'
        },
        {
          title: '单位',
          field: 'unit'
        },
        {
          title: '采购数量',
          field: 'counts'
        },
        {
          title: '计划生产数量',
          field: 'counts'
        }
      ]
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getSaleList()
    this.getDetailData()
  },
  methods: {
    // 获取业务员列表
    getSaleList() {
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          filterData: {
            role_name: '业务员'
          },
          pageSize: this.$constant.pageMax
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let result = res.data.page.list.map((item) => {
            return {
              key: item.nick_name,
              val: item.nick_name,
              id: item.account_id
            }
          })
          this.formColumn.forEach((item) => {
            if (item.field === 'cao_zuo_yuan') item.options = this.$util.deepCopy(result)
          })
        } else {
          return
        }
      })
    },
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/factory/buildFactoryDingDan',
        params: {
          cai_gou_stock_id: this.data.cai_gou_stock_id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          let data = this.$util.deepCopy(res.data)
          data.list = [{ ...data.process }]
          this.selfDetail = this.$util.deepCopy(data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupPush() {
      this.formColumn.forEach((itemSt) => {
        if (itemSt.field === 'cao_zuo_yuan') {
          itemSt.options.forEach((itemNd) => {
            if (itemNd.val === this.selfDetail.data.cao_zuo_yuan) {
              this.selfDetail.data.cao_zuo_yuan_id = itemNd.id
            }
          })
        }
      })

      this.$api({
        method: 'post',
        url: '/admin/gcvip/factory/saveOrUpdate',
        data: this.selfDetail
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.popupCancel()
            this.$emit('success')
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
